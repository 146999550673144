//import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class NotFound extends Component {
  static propTypes = {}

  render() {
    return (
      <div>NotFound</div>
    )
  }
}

export default NotFound